/*eslint max-lines: ["error", 219]*/
import { css, SerializedStyles } from '@emotion/react';
import { KeyboardEvent } from 'react';

import {
  CrossIcon,
  FilePlusIcon,
  spacings,
  theme,
} from '@dialog/design-system';

import { FilterDisplayedValue } from 'components/shared/FilterDisplayedValue/FilterDisplayedValue';
import { useFiltersOption } from 'hooks/filters/useFiltersOption';

import {
  AugmentedOperation,
  AugmentedRule,
  AugmentedRuleGroup,
  HistoryStateOption,
} from '../types';

const actionItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '100%',
});

// eslint-disable-next-line complexity
export const SimpleRule = ({
  ruleGroupParent,
  onCreateGroup,
  displayEditFieldsIcon = false,
  rule,
  onPressEnter,
  handleDeleteFilter,
  onEditFilter,
  enableDeletion = true,
  enableGroupCreation = true,
  customCss,
}: {
  ruleGroupParent?: AugmentedRuleGroup;
  onCreateGroup?: (rule: AugmentedOperation) => void;
  displayEditFieldsIcon?: boolean;
  rule: AugmentedOperation;
  onPressEnter: (
    event: KeyboardEvent<HTMLDivElement>,
    filter: AugmentedRule,
  ) => void;
  handleDeleteFilter: (
    event: React.MouseEvent | KeyboardEvent<HTMLDivElement>,
    filter: AugmentedRule,
    ruleGroup?: AugmentedRuleGroup,
  ) => void;
  onEditFilter: (
    rule: AugmentedOperation,
    options?: HistoryStateOption,
  ) => void;
  enableDeletion?: boolean;
  enableGroupCreation?: boolean;
  customCss?: SerializedStyles;
}): JSX.Element => {
  const { getFilterIcon } = useFiltersOption();

  const handleCreationGroup = (
    event: React.MouseEvent | KeyboardEvent<HTMLDivElement>,
    currentRule: AugmentedOperation,
  ) => {
    event.stopPropagation();
    if (onCreateGroup !== undefined) {
      onCreateGroup(currentRule);
    }
  };

  const ruleField = rule.field;

  return (
    <button
      data-testid={`${ruleField}-edit-button`}
      css={[
        css({
          cursor: 'pointer',
          borderRadius: theme.borderRadius.md,
          position: 'relative',
          outline: 'unset',
          width: '100%',
          background: theme.palette.grey50,
          border: `1px solid ${theme.palette.grey100}`,
          padding: `${spacings.md} ${spacings.lg}`,

          '&:hover': {
            '#delete-btn': {
              opacity: '1',
            },

            '#rule-label': {
              ' webkitMaskImage':
                'linear-gradient(to right, rgb(255 27 27), rgb(0 0 0 / 0%)), linear-gradient(to right, rgb(255 0 0), rgb(0 0 0 / 0%))',
            },
          },
        }),
        customCss,
      ]}
      key={`${ruleField}${rule.operator}${rule.value ?? ''}}`}
      onClick={() =>
        onEditFilter(
          { ...rule, field: ruleField },
          {
            ruleGroupParent,
            selectedRule: rule,
          },
        )
      }
    >
      <div
        css={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        })}
      >
        <div
          id="rule-label"
          css={css({
            display: 'flex',
            alignItems: 'center',
            gap: spacings.sm,
          })}
        >
          {getFilterIcon(ruleField)}
          <FilterDisplayedValue rule={rule} />
        </div>
        {displayEditFieldsIcon ? (
          <div
            css={css({
              position: 'absolute',
              right: 0,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {enableDeletion ? (
              <div
                id="delete-btn"
                css={css({
                  position: 'absolute',
                  height: '100%',
                  right: enableGroupCreation ? '60px' : '16px',
                  opacity: '0',
                  width: '30px',
                  display: 'flex',
                  alignItems: 'center',
                })}
              >
                <div
                  css={[
                    actionItemStyle,
                    css({
                      width: '28px',
                      height: '28px',

                      '&:hover': {
                        borderRadius: theme.borderRadius.sm,
                        background: theme.palette.grey100,
                      },
                    }),
                  ]}
                  data-testid={`${ruleField}-delete-button`}
                  role="button"
                  tabIndex={0}
                  onKeyDown={event => onPressEnter(event, rule)}
                  onClick={event =>
                    handleDeleteFilter(event, rule, ruleGroupParent)
                  }
                >
                  <CrossIcon color={theme.palette.grey600} />
                </div>
              </div>
            ) : null}

            {enableGroupCreation ? (
              <div
                css={[
                  actionItemStyle,
                  css({
                    borderTopRightRadius: theme.borderRadius.md,
                    borderBottomRightRadius: theme.borderRadius.md,
                    borderLeft: `1px solid ${theme.palette.grey100}`,
                    background: theme.palette.classic.white,

                    '&:hover': {
                      background: theme.palette.grey100,
                    },
                  }),
                ]}
                data-testid={`${ruleField}-create-group-button`}
                role="button"
                tabIndex={0}
                onKeyDown={event => handleCreationGroup(event, rule)}
                onClick={event => handleCreationGroup(event, rule)}
              >
                <FilePlusIcon color={theme.palette.grey600} />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </button>
  );
};
