import { Metrics, MetricsPeriod } from '@dialog/organization-contracts';

export const getGenerateOrganizationKey = (): readonly ['organization'] => {
  return ['organization'];
};

export const getGenerateOrganizationIntegrationsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly [
  'organizationIntegrations',
  {
    readonly organizationSlug: string;
  },
] => {
  return [
    'organizationIntegrations',
    {
      organizationSlug,
    },
  ];
};

export const getGenerateMetafieldsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['metafields', { readonly organizationSlug: string }] => {
  return ['metafields', { organizationSlug }];
};

export const getGenerateCollectionsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['collections', { readonly organizationSlug: string }] => {
  return ['collections', { organizationSlug }];
};

export const getGenerateAiConfigKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['aiConfig', { readonly organizationSlug: string }] => {
  return ['aiConfig', { organizationSlug }];
};

export const getGenerateWidgetSettingsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['widgetSettings', { readonly organizationSlug: string }] => {
  return ['widgetSettings', { organizationSlug }];
};

export const getBillingDataKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['billingData', { readonly organizationSlug: string }] => {
  return ['billingData', { organizationSlug }];
};

export const getGenerateProductsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['products', { readonly organizationSlug: string }] => {
  return ['products', { organizationSlug }];
};

export const getMetricsKey = ({
  organizationSlug,
  type,
  period,
}: {
  organizationSlug: string;
  type: Metrics;
  period: MetricsPeriod;
}): readonly [
  'dashboards-metrics',
  {
    readonly organizationSlug: string;
    readonly type: Metrics;
    readonly period: MetricsPeriod;
  },
] => {
  return ['dashboards-metrics', { organizationSlug, type, period }];
};

export const getGenerateConversationsClusterKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly [
  'conversations-cluster',
  { readonly organizationSlug: string },
] => {
  return ['conversations-cluster', { organizationSlug }];
};

export const getChatHistoryByOrgAndSessionKey = ({
  sessionId,
  organizationSlug,
}: {
  sessionId: string;
  organizationSlug: string;
}): readonly [
  'chat-history-by-org-and-session',
  {
    readonly sessionId?: string;
    readonly organizationSlug?: string;
  },
] => {
  return ['chat-history-by-org-and-session', { sessionId, organizationSlug }];
};

export const getChatHistoryByOrgKey = ({
  organizationSlug,
  pkNext,
  skNext,
  gsi1pkNext,
  gsi1skNext,
}: {
  organizationSlug: string;
  pkNext?: string;
  skNext?: string;
  gsi1pkNext?: string;
  gsi1skNext?: string;
}): readonly [
  'chat-history-by-org',
  {
    readonly organizationSlug?: string;
    readonly pkNext?: string;
    readonly skNext?: string;
    readonly gsi1pkNext?: string;
    readonly gsi1skNext?: string;
  },
] => {
  return [
    'chat-history-by-org',
    { organizationSlug, pkNext, skNext, gsi1pkNext, gsi1skNext },
  ];
};
