import { ThemeProvider as ThemeEmotion } from '@emotion/react';
import Intercom from '@intercom/messenger-js-sdk';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import flagsmith from 'flagsmith'; //Add this line if you're using flagsmith via npm
import { FlagsmithProvider } from 'flagsmith/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';

import { theme } from '@dialog/design-system';

import { AuthProvider } from 'components/AuthProvider/AuthProvider';
import { PageLoader } from 'components/shared';
import { ToastProvider } from 'context/ToastProvider';
import { flattenMessages } from 'services/i18n';
import { enUSMessages } from 'translations';

import AppRoutes from './AppRoutes';

posthog.init(import.meta.env.VITE_POSTHOG_API_KEY as string, {
  api_host: 'https://eu.posthog.com',
  autocapture: true,
  capture_pageleave: true,
  capture_pageview: true,
  capture_performance: true,
});

export const config = {
  Auth: {
    region: 'eu-west-1',
    userPoolId: process.env.VITE_USER_POOL_ID as string,
    userPoolWebClientId: process.env.VITE_USER_POOL_WEB_CLIENT_ID as string,
    authenticationFlowType: 'CUSTOM_AUTH',
  },
};

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://0b48a9abfb4f45dbb247c74fa9b6d2b9@o4505226828775424.ingest.sentry.io/4505226833100800',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

declare global {
  interface Window {
    growsumo?: {
      data: Record<string, string>;
      createSignup: () => void;
    };
  }
}

Amplify.configure(config);
const queryClient = new QueryClient();
const intlMessages = flattenMessages(enUSMessages);

const App = (): JSX.Element => {
  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID as string,
  });

  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID: process.env.VITE_FLAGSMITH_ID as string,
        cacheFlags: true,
        cacheOptions: { ttl: 3600000, skipAPI: true },
      }}
    >
      <ThemeEmotion theme={theme}>
        <IntlProvider
          locale="en-US"
          messages={intlMessages}
          defaultLocale="en-US"
        >
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ToastProvider>
                <PostHogProvider client={posthog}>
                  <Suspense fallback={<PageLoader />}>
                    <AppRoutes />
                  </Suspense>
                </PostHogProvider>
              </ToastProvider>
            </AuthProvider>
          </QueryClientProvider>
        </IntlProvider>
      </ThemeEmotion>
    </FlagsmithProvider>
  );
};

export default App;
