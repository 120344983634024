/*eslint max-lines: ["error", 212]*/
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { FromSchema } from 'json-schema-to-ts';
import omit from 'lodash/omit';

import {
  addUserToOrgContract,
  loginContract,
  OrganizationAccess,
  OrganizationUser,
  registerWithOrganizationInvitationContract,
  removeUserFromOrgContract,
  signInContract,
  SignUpBody,
  StatusResponseTypes,
  uploadProfilePictureContract,
} from '@dialog/auth-contracts';

import client, { createAuthClient } from 'services/networking/client';

interface IAuthCurrentUser {
  id?: string;
  username: string;
  attributes: {
    'custom:authChallenge'?: string;
    sub: string;
    email: string;
    name: string;
    'custom:lastName'?: string;
    'custom:position'?: string;
    picture?: string;
    'custom:organizationsAccess'?: string;
    'custom:tosAgreement': string;
  };
}

export const login = async ({
  email,
}: {
  email: string;
}): Promise<{ status: string }> => {
  await getAxiosRequest(loginContract, client, {
    body: {
      email,
    },
  });

  return { status: 'success' };
};

export const register = async ({
  email,
  firstName,
  lastName,
}: SignUpBody): Promise<{ status: string }> => {
  await getAxiosRequest(signInContract, client, {
    body: {
      email,
      firstName,
      lastName,
    },
  });

  return { status: 'success' };
};

export const fetchCurrentUserData = async (): Promise<
  OrganizationUser | undefined
> => {
  const currentUserInfo =
    (await Auth.currentUserInfo()) as IAuthCurrentUser | null;

  if (currentUserInfo === null) {
    return undefined;
  }

  let organizationsAccess: OrganizationAccess[] = [];

  if (currentUserInfo.attributes['custom:organizationsAccess'] !== undefined) {
    organizationsAccess = JSON.parse(
      currentUserInfo.attributes['custom:organizationsAccess'],
    ) as OrganizationAccess[];
  }

  const user = {
    ...currentUserInfo,
    ...currentUserInfo.attributes,
    'custom:organizationsAccess': organizationsAccess,
  };

  const organizationUser = omit(user, ['attributes', 'sub']);

  return organizationUser;
};

export const inviteUser = async (
  email: string,
  organizationSlug: string,
): Promise<OrganizationUser> => {
  const response = await getAxiosRequest(
    addUserToOrgContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
      body: {
        email,
      },
    },
  );

  return response.data;
};

export const registerWithInvitation = async ({
  firstName,
  lastName,
  organizationSlug,
}: {
  firstName: string;
  lastName: string;
  organizationSlug: string;
}): Promise<{ status: 'failed' | 'success' }> => {
  const response = await getAxiosRequest(
    registerWithOrganizationInvitationContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
      body: {
        firstName,
        lastName,
      },
    },
  );

  return response.data;
};

export const loginWithChallenge = async (
  challenge: string,
): Promise<CognitoUser | undefined> => {
  try {
    const [email, code] = challenge.split(',');
    const user = (await Auth.signIn(email as string)) as CognitoUser;
    await Auth.sendCustomChallengeAnswer(user, code as string);

    return user;
  } catch (err) {
    console.error(JSON.stringify(err));

    return undefined;
  }
};

export const removeUserFromOrg = async ({
  organizationSlug,
  username,
}: {
  organizationSlug: string;
  username: string;
}): Promise<{ status: StatusResponseTypes }> => {
  const response = await getAxiosRequest(
    removeUserFromOrgContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
        username,
      },
    },
  );

  return response.data;
};

export const refreshUserSession = async (): Promise<CognitoUserSession> => {
  const session = await Auth.currentSession();
  const cognitoUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;

  return new Promise(resolve => {
    cognitoUser.refreshSession(session.getRefreshToken(), async () => {
      const newSession = await Auth.currentSession();
      resolve(newSession);
    });
  });
};

export const updateUserAvatar = async ({
  userId,
  base64,
}: {
  userId: string;
  base64: string;
}): Promise<void> => {
  const clientAuthentified = await createAuthClient();

  await clientAuthentified.post<
    FromSchema<typeof uploadProfilePictureContract.outputSchema>
  >(`/user/${userId}/upload-profile-picture`, base64, {
    headers: {
      'content-type': 'text/plain',
    },
  });

  return;
};
